<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Montage <br />
    </h2>
    <article class="container mx-auto p-4 md:p-32">
      <hw class="text-4xl lg:text-6xl mb-16" style="color: #f27405">
        Ihren Sonnenschutz montieren lassen – fachgerecht von BE-RO-MA in Berlin
      </hw>
      <figure
        class="w-72 lg:w-full mx-4 my-2 flex flex-row justify-center space-x-4"
      >
        <img
          class="h-72"
          src="../assets/img/projekte/projekt42.webp"
          alt="montage berlin beroma"
        />
        <img
          class="hidden md:block h-72"
          src="../assets/img/landingpage/mini-gallery-5.webp"
          alt="montage berlin beroma"
        />
      </figure>
      <p class="lg:mx-32 mx-4 my-12 text-gray-600">
        Einen Sonnenschutz montieren – wie schwer kann das schon sein? Die
        Antwort ist simpel: sehr! In Berlin können unsere Monteure Abhilfe
        leisten, denn professionelle und saubere Arbeit wird bei Ihrem
        Fachanbieter BE-RO-MA großgeschrieben. <br /><br />
      </p>
      <h4 class="text-2xl pb-3 text-center font-bold" style="color: #f27405">
        Warum Sie sich für BE-RO-MA in Berlin entscheiden sollten:
      </h4>
      <div
        class="flex flex-col lg:flex-row pb-10 text-gray-600 justify-center leading-relaxed text-2xl"
      >
        <ul class="mx-2">
          <li class="flex flex-row">
            <div class="mx-1" style="color: #f27405">
              <i class="fas fa-check"></i>
            </div>
            <span>Kompetente Fachberatung <br /></span>
          </li>
          <li class="flex flex-row">
            <div class="mx-1" style="color: #f27405">
              <i class="fas fa-check"></i>
            </div>
            <span>Planung und Durchführung<br /></span>
          </li>
          <li class="flex flex-row">
            <div class="mx-1" style="color: #f27405">
              <i class="fas fa-check"></i>
            </div>
            <span>Fachmontage vor Ort<br /></span>
          </li>
        </ul>
        <ul class="mx-2">
          <li class="flex flex-row">
            <div class="mx-1" style="color: #f27405">
              <i class="fas fa-check"></i>
            </div>
            <span>Zeitnahe Termine<br /></span>
          </li>
          <li class="flex flex-row">
            <div class="mx-1" style="color: #f27405">
              <i class="fas fa-check"></i>
            </div>
            <span>Hauseigene Monteure<br /></span>
          </li>
          <li class="flex flex-row">
            <div class="mx-1" style="color: #f27405">
              <i class="fas fa-check"></i>
            </div>
            <span>Eigene Fachwerkstatt<br /></span>
          </li>
        </ul>
      </div>
      <p class="lg:mx-32 mx-4 my-12 text-gray-600">
        Wir von BE-RO-MA bieten Ihnen eine kompetente Fachberatung, Planung und
        Durchführung: Ihren Sonnenschutz montieren wir ganz nach Ihren
        Vorstellungen! Natürlich alles von unseren hauseigenen Monteuren in
        Berlin, die sich stets einen zeitnahen Termin für Sie freinehmen. Wenn
        Sie Ihren Sonnenschutz von uns montieren lassen möchten, vereinbaren Sie
        mit uns in Berlin einen Termin.
        <br /><br />
      </p>
      <contacticons></contacticons>
    </article>
  </section>
</template>
<script>
import contacticons from "../components/contact";
export default {
  name: "App",
  components: {
    contacticons,
  },
};
</script>
